<template>
  <div class="WealthPlanning">
    <div class="banner">
      <img width="100%" src="../assets/banner2.png" alt="" />
      <div class="title">
        <div class="h1">WEALTH PLANNING</div>
      </div>
    </div>
    <div class="main">
      <p class="titles border">
        In wealth planning, there is no sole solution that fits all.
      </p>
      <p class="titles">
        You will have your own goals in life. To help you achieve them, we will
        understand your needs today, and ambitions for the future. Next, we will
        assess your situation, looking at such aspects as how your lifestyle
        might change, income and spending, assets and liabilities, and
        retirement plans.
      </p>
      <div class="trust">
        <div
          v-for="(item, index) in trust.text"
          :key="index"
          :style="{ backgroundImage: `url(${item.url})` }"
        >
          <!-- @click="handleLoding(index)" -->
          <p style="font-size: 20px; font-weight: 500; font-family: Georgia">
            {{ item.text }}
          </p>
          <!-- <p class="text2">{{ item.text2 }}</p> -->
          <p
            style="margin-top: 0px; font-family: contert"
            v-html="item.more"
          ></p>
        </div>
      </div>
      <p class="titles">
        We look forward to working with you to develop a Trust structure that
        achieves your objectives and needs given your unique circumstances.
      </p>
      <p class="titles">Please contact us for more information.</p>
      <el-dialog
        :title="trust.text[data].text"
        :visible.sync="centerDialogVisible"
        center
      >
        <div class="dialogtitle" style="">
          {{ Loding[data].title }}
        </div>
        <div class="dialogtext">
          <p
            style="color: #000"
            v-for="(item, index) in Loding[data].text"
            :key="index"
          >
            {{ item }}
          </p>
        </div>
      </el-dialog>
    </div>
    <div class="foot">
      <h1 id="ACustomizedTrust">Customized Trust Services</h1>
      <div class="navpc">
        <!-- <img src="../assets/ACustomizedTrust2.png" alt="" /> -->
        <div class="top">
          <div class="tit">
            <div class="titleee">
              Understanding your needs by listening to you wholeheartedly
            </div>
            <div class="top1">
              Listening to you wholeheartedly is a crucial step for our
              understanding of your wealth inheritance plan and its objectives.
            </div>
          </div>
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-xiangyoujiantou"></use>
          </svg>
          <div class="tit">
            <div class="titleee">Analysis and Effective Communication</div>
            <div class="top2">
              By thorough discussion with you and in-depth analysis, we are
              certainly able to deliver our professional trust inheritance
              service to you, which will meet your every objective. We will
              adhere to the law and regulations for the execution of your trust
              to achieve wealth inheritance and capital growth for you.
            </div>
          </div>
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-xiangyoujiantou"></use>
          </svg>
          <div class="tit">
            <div class="titleee">Implementation of Your Customized Trust</div>
            <div class="top3">
              Your Trust Manager will implement your trust that is tailor-made
              for you in conformity to your desire.
            </div>
          </div>
        </div>
        <div class="bottom">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-xiangyoujiantou"></use>
          </svg>
          <div class="tit">
            <div class="titleee">Management of<br />your Trust</div>
            <div class="bottom1">
              We will monitor and evaluate the performance of the trust on
              regular basis and make the necessary adjustment to the allocation
              of the assets in trust to meet your needs.
            </div>
          </div>
          <svg style="display: none" class="icon" aria-hidden="true">
            <use xlink:href="#icon-xiangyoujiantou"></use>
          </svg>
          <div style="display: none" class="tit">
            <div class="titleee">Management of<br />your Trust</div>
            <div class="bottom2">
              We will monitor and evaluate the performance of the trust on
              regular basis and make the necessary adjustment to the allocation
              of the assets in trust to meet your needs.
            </div>
          </div>
        </div>
      </div>

      <div class="navmin">
        <div class="tit">
          Understanding your needs<br />
          by listening to you<br />
          wholeheartedly
        </div>
        <div class="text">
          Listening to you wholeheartedly is a crucial step for our
          understanding of your wealth inheritance plan and its objectives
        </div>
        <div class="m_icon">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-xiangyoujiantou"></use>
          </svg>
        </div>
        <div class="tit">Analysis and Effective Communication</div>

        <div class="text">
          By thorough discussion with you and in-depth analysis, we are
          certainly able to deliver our professional trust inheritance service
          to you, which will meet your every objective. We will adhere to the
          law and regulations for the execution of your trust to achieve wealth
          inheritance and capital growth for you.
        </div>
        <!-- <div class="m_icon">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-xiangyoujiantou"></use>
          </svg>
        </div>
        <div class="tit">Customized Trust Plan</div>

        <div class="text">
          By understanding your specific needs, our professional team can
          certainly tailor-make a trust plan dedicated to serve such needs the
          best by proper allocation of the assets in your trust.
        </div> -->
        <div class="m_icon">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-xiangyoujiantou"></use>
          </svg>
        </div>
        <div class="tit">Implementation of Your Customized Trust</div>

        <div class="text">
          Your Trust Manager will implement your trust that is tailor-made for
          you in conformity to your desire.
        </div>
        <div class="m_icon">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-xiangyoujiantou"></use>
          </svg>
        </div>
        <div class="tit">Management of your Trust</div>
        <div class="text">
          We will monitor and evaluate the performance of the trust on regular
          basis and make the necessary adjustment to the allocation of the
          assets in trust to meet your needs.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: 0,
      centerDialogVisible: false,
      trust: {
        more: "LEARN MORE",
        text: [
          {
            text: "Wealth planning",
            more: "In-depth planning to help you achieve your goals",
            text2:
              "Do you want to enroll a popular real estate investment project however stepped back due to lack of experience? Have you missed many great investment chances because of hesitation?",
            url: "https://www.hongkongtrust.com.hk/vue/img/WealthPlanning1.jpg",
          },
          {
            text: "Trust structure",
            more: "Deploying the appropriate solutions for your family or business",
            text2:
              "Are you concerned future or unpredictable accidents when raise children and support parents’ daily living? Are you struggling your every decision would burden your family?",
            url: "https://www.hongkongtrust.com.hk/vue/img/WealthPlanning6.jpg",
          },
          {
            text: "Retirement",
            more: "Providing for a comfortable future",
            text2:
              "Are you planning to start investment to gain assets growth due to your fixed income reach a plateau? Are you hesitated to invest due to lack of experience?",
            url: "https://www.hongkongtrust.com.hk/vue/img/WealthPlanning2.jpg",
          },
          {
            text: "Taxation",
            more: "Understanding your tax obligations",
            text2:
              "Do you want your children or yourself to concentrate on study with stable financial support during study abroad? Are you worried that unnecessary expenses would exceed financial budget?",
            url: "https://www.hongkongtrust.com.hk/vue/img/WealthPlanning3.jpg",
          },
          {
            text: "Inheritance",
            more: "Transferring your wealth to the next generation",
            text2:
              "Worked hard for years, are you longing for retirement without any worries of daily living? Are you concerned that your assets have not been properly managed and failed for multi-generational transfer?",
            url: "https://www.hongkongtrust.com.hk/vue/img/WealthPlanning5.jpg",
          },
          /* {
            text: "6.How to do perpetual charitable giving and contribute to the society? (Charity Trust)",
            text2:
              "Are you full of enthusiasm and passionate contributing to the society and our motherland? Are you expecting to carry out cross-generation inheritance of family value and faith?",
            url: "https://www.hongkongtrust.com.hk/vue/img/WealthPlanning6.jpg",
          }, */
        ],
      },
      Loding: [
        {
          title:
            "Do you want to enroll a popular real estate investment project however stepped back due to lack of experience? Have you missed many great investment chances because of hesitation?",
          text: [
            "Real estate is one of the most stable and defensive investment projects. Under professional management, only high-qualified and professional investors will be appointed to serve to client and maximize the return of real estate investment projects. At the same time, the professional team will also provide reliable market analysis and information to facilitate settlor’s investment decisions.",
            "Are you interested in choosing a professional team which provides the best and responsible investment assistance?",
            "To know more details, you may visit “Real Estate Trust” from the TRUST SERVICE CATEGORY section. You may also contact your financial planner for further consultation.",
          ],
        },
        {
          title:
            "Are you concerned future or unpredictable accidents when raise children and support parents’ daily living? Are you struggling your every decision would burden your family?",
          text: [
            "The professional team can fulfill your various needs by tailor-made asset management plan, specific asset inheritance and distribution. From supporting current daily family expenses to children and grandchildren business incentives in the future, it will be executed accurately as your plan. Furthermore, setting up a trust achieves segregation of asset, your assets settled into a trust will gain immediate protection from the risk of investment because the legal ownership of these assets would have been transferred to the trustee.",
            "Are you interested in choosing a professional team which provides the most secured and comprehensive asset management plan service?",
            "To know more details, you may visit “Family Trust” from the TRUST SERVICE CATEGORY section. You may also contact your financial planner for further consultation.",
          ],
        },
        {
          title:
            "Are you planning to start investment to gain assets growth due to your fixed income reach a plateau? Are you hesitated to invest due to lack of experience?",
          text: [
            "Risk can be avoided to break through plateau of the fixed income. Under professional management, the consistent returns of trust assets could be maintained at between 6% to 10%. Therefore, it could overcome the effects of inflation on the settlor’s wealth. This provides assurance that the purchasing power of the settlor’s wealth is not only maintained, but even grows over time. ",
            "Are you interested in choosing a professional team which provides sustainable value-added management service?",
            "To know more details, you may visit “Savings Trust” from the TRUST SERVICE CATEGORY section. You may also contact your financial planner for further consultation.",
          ],
        },
        {
          title:
            "Do you want your children or yourself to concentrate on study with stable financial support during study abroad? Are you worried that unnecessary expenses would exceed financial budget?",
          text: [
            "The professional team can assist the settlor of planning future education and development, reserving enough funds for study abroad and living expenses in advance; securing the assets while also realizing a steady growth to effectively reduce the cost of study abroad as well.",
            "Moreover, the return generated by the education trust will be exempted from individual income tax which avoiding additional expenses.",
            "Are you interested in choosing a professional team which provides the most comprehensive education fund planning service?",
            "To know more details, you may visit “Education Trust” from the TRUST SERVICE CATEGORY section. You may also contact your financial planner for further consultation.",
          ],
        },
        {
          title:
            "Worked hard for years, are you longing for retirement without any worries of daily living? Are you concerned that your assets have not been properly managed and failed for multi-generational transfer?",
          text: [
            "A professional team could tailor-made your family wealth inheritance plan, you could set diversified inheritance conditions as your will to ensure multi-generational transfer without any worries of daily life. Also, it assures family harmony and achieves the inheritance of family wealth and values, avoiding dispute over wealth inheritance too.",
            "Are you interested in choosing a professional team which provides service of multi-generational transfer of wealth?",
            "To know more details, you may visit “Family Trust” from the TRUST SERVICE CATEGORY section. You may also contact your financial planner for further consultation.",
          ],
        },
        {
          title:
            "Are you full of enthusiasm and passionate contributing to the society and our motherland? Are you expecting to carry out cross-generation inheritance of family value and faith?",
          text: [
            "The professional team can provide complete asset management for your charitable giving, it could be made perpetual from the income generated annually therefrom. Also, setting up a charity trust with family, then the charitable giving can be done out of the name of family fund. It could result in enhancing family influence which contributing to the sustainability of family business and gain cross-generation inheritance of family values.",
            "Are you interested in choosing a professional team which provides management service to achieve your will of perpetual charitable giving?",
            "To know more details, you may visit “Charity Trust” from the TRUST SERVICE CATEGORY section. You may also contact your financial planner for further consultation.",
          ],
        },
      ],
    };
  },
  methods: {
    handleLoding(index) {
      this.data = index;
      this.centerDialogVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  transition: all 0.6s;
  cursor: pointer;
}
.WealthPlanning {
  ::v-deep .el-dialog {
    width: 60vw;
  }
}
.dialogtitle {
  font-size: 24px;
  color: #000;
  font: weight 500;
  border-top: 1px solid #000;
  padding-top: 10px;
  word-wrap: break-word;
  word-break: normal;
}
.dialogtext {
  margin-top: 20px;
  padding: 20px 20px;
  background: #f5f5f5;
  word-wrap: break-word;
  word-break: normal;
  p:last-child {
    text-decoration: underline;
    padding-bottom: 20px;
  }
  p {
    margin: 0px;
    margin-bottom: 40px;
  }
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  font-size: 50px;
  color: #fff;
}
.WealthPlanning {
  --swiper-theme-color: #000;

  //--swiper-pagination-color: #00ff33; /* 两种都可以 */
}
.swiper-pagination {
  ::v-deep .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    margin: 45px 10px 170px 10px;
  }
  position: relative;
}

.imgs {
  position: absolute;
  right: 0px;
  bottom: 0px;
}
.WealthPlanning {
  color: #fff;
}

.main {
  width: 60vw;
  margin: 60px auto;
  .trust {
    display: flex;
    color: #000;
    flex-wrap: wrap;
    margin-top: 40px;
    justify-content: space-between;
    .text2 {
      font-size: 14px;
      /* padding-bottom: 100px; */
      margin: 0px;
    }
    /* div:nth-child(1) {
      p {
        color: #fff;
      }
    } */
    /* div:nth-child(4) {
      background: #000;
      p {
        color: #fff;
      }
    } */
    /*   div:nth-child(5) {
      background: #000;
      p {
        color: #fff;
      }
    } */
    div {
      width: 46%;
      text-align: left;
      /* padding: 0% 2%; */
      margin-bottom: 30px;
      height: 315px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-size: cover;
      background: no-repeat;
      overflow: hidden;
    }
    div:hover {
      transform: scale(1.01);
      transition: all 0.3s;
    }
  }
  p {
    color: #fff;
    font-size: 16px;
    text-align: center;
    letter-spacing: 2px;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .titles {
    color: #000;
    margin: 0px;
    font-size: 20px;
    letter-spacing: 0;
    text-align: left;
    font-family: contert;
  }
  .border {
    font-weight: 500;
    font-family: Georgia;
  }
}
/* .before {
  width: 100vw;
  height: 994px;
  background: #000;
  opacity: 0.5;
  position: absolute;
  top: 0px;
}
.before2 {
  width: 100vw;
  height: 701px;
  background: #000;
  opacity: 0.5;
  position: absolute;
  top: 0px;
  z-index: 6;
} */
.banner {
  width: 100vw;
  position: relative;
}
.title {
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  /* z-index: 100; */
  position: absolute;
  top: 45%;

  .h1 {
    font-size: 55px;
  }
}
.foot {
  color: #000;
  font-size: 38px;
  margin-bottom: 100px;
  background: url("../assets/ACustomizedTrust1.png") no-repeat;
  background-size: cover;
  padding-top: 20px;
  h1 {
    font-weight: 500;
    margin: 0px;
    font-size: 38px;
    color: #fff;
    margin: 20px;
    /* margin-bottom: 50px; */
  }
  .navmin {
    display: none;
    .text {
      font-family: contert;
    }
  }
  .navpc {
    width: 60vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    color: #fff;
    .top1 {
      position: absolute;
      /* width: 25%; */
      font-size: 16px;
      text-align: left;
      font-family: contert;
      /* left: 1.5%; */
      top: 110px;
    }
    .top2 {
      position: absolute;
      /* width: 25%; */
      font-size: 16px;
      text-align: left;
      /* left: 36.5%; */
      top: 110px;
      font-family: contert;
    }
    .top3 {
      position: absolute;
      /* width: 25%; */
      font-size: 16px;
      text-align: left;
      /* right: 3.9%; */
      top: 110px;
      font-family: contert;
    }
    .bottom1 {
      position: absolute;
      /* width: 24%; */
      font-size: 16px;
      text-align: left;
      /* left: 23%; */
      top: 110px;
      font-family: contert;
    }
    .bottom2 {
      position: absolute;
      /* width: 24%; */
      font-size: 16px;
      text-align: left;
      /* right: 15%; */
      top: 110px;
      font-family: contert;
    }
    .top {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-bottom: 350px;
      .tit:nth-child(1) .titleee {
        width: 90%;
      }
      .tit {
        border: 1px solid;
        width: 27%;
        font-size: 20px;
        height: 105px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .bottom {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 400px;
      .tit {
        border: 1px solid;
        width: 27%;
        font-size: 20px;
        height: 105px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 20px;
        margin-right: 70px;
      }
    }
  }
  .navpc {
    width: 60vw;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    div {
      /* width: 25%; */
      position: relative;
      .p1 {
        padding: 0px 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 105px;
        font-size: 20px;
        border: 1px solid;
      }
      .p2 {
        position: absolute;
        font-size: 17px;
      }
    }
  }
}
@media (min-width: 1025px) and (max-width: 1500px) {
  .main {
    width: 80vw;
  }
  .foot .navpc {
    width: 80vw;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .title .h1 {
    font-size: 36px;
  }
  .icon {
    font-size: 30px;
  }
  .main {
    width: 80vw;
    margin-top: 50px;
    margin-bottom: 20px;
    .trust div {
      height: 250px;
    }
    p {
      font-size: 16px;
    }
  }
  .foot h1 {
    margin: 0px;
  }
  /* .foot .navpc .top .tit:nth-child(1) .titleee {
    width: 100%;
  } */
  .foot .navpc .top .tit:last-child .titleee {
    width: 83%;
  }
  .foot .navpc {
    width: 80vw;
    margin-top: 20px;

    .top {
      margin-bottom: 370px;

      .tit {
        font-size: 16px;
        height: 100px;
      }
    }
    .top1 {
      font-size: 14px;
    }
    .top2 {
      font-size: 14px;
      /* width: 28%; */
    }
    .top3 {
      font-size: 14px;
    }
    .bottom {
      margin-bottom: 260px;
      .tit {
        font-size: 16px;
        height: 100px;
      }
    }
    .bottom1 {
      font-size: 14px;
      bottom: 425px;
    }
    .bottom2 {
      font-size: 14px;
      bottom: 425px;
    }
  }
}
@media (max-width: 767px) {
  // 手机背景图
  // .banner img {
  //   height: 520px;
  //   object-fit: cover;
  // }
  .dialogtitle {
    font-size: 16px;
  }
  .dialogtext {
    p {
      font-size: 14px;
    }
  }
  .WealthPlanning {
    ::v-deep .el-dialog {
      width: 100vw;
    }
  }
  .title {
    .h1 {
      font-size: 30px;
    }
    top: 35%;
  }
  .banner {
    margin-top: 64px;
  }
  .main {
    width: 78vw;
    margin: 30px auto 0px;
    .titles {
      font-size: 14px;
    }
    .trust {
      margin-top: 20px;
      div {
        width: 100%;
        padding: 2% 4%;
        height: 200px;
        background-size: cover;
      }
      p {
        font-size: 14px;
      }
    }
  }
  .foot {
    margin-top: 20px;
    background-image: none;
    margin-bottom: 0px;
    background: #093f8d;
    h1 {
      font-size: 20px;
      color: #fff;
      margin: 0px;
    }
    .navpc {
      display: none;
    }
    .navmin {
      /* color: #fff; */
      width: 90vw;
      margin: 0 auto;
      text-align: justify;
      padding-bottom: 20px;
      display: block;
      .tit {
        width: 70vw;
        margin: 0 auto;
        font-size: 16px;
        font-weight: 500;
        margin-top: 10px;
        text-align: center;
        border: 1px solid #fff;
        color: #fff;
        padding: 4vw;
      }
      .m_icon {
        text-align: center;
        transform: rotate(90deg);
        margin: 8px 0;
      }
      .text {
        width: 78vw;
        margin: 7px auto 0;
        font-size: 14px;
        color: #fff;
        text-align: left;
      }
    }
  }
}
</style>
